// todo: 他プロジェクトに合わせていたがファイル名 content は constants のタイポだったらしいのでファイル名constantsに変える

export const LINK = {
  // 各種サービスサイト
  VKET_STORE: 'https://store.vket.com',
  CLOUD: {
    JP: 'https://cloud.vket.com/',
    EN: 'https://cloud.vket.com/en',
  },
  CLOUD_HOWTO: {
    JP: 'https://cloud.vket.com/howto',
    EN: 'https://cloud.vket.com/en/howto',
  },
  VIRTUAL_MARKET: {
    JP: 'https://event.vket.com/',
    EN: 'https://event.vket.com/en',
  },
  MUSV: 'https://music4.vket.com/',
  COMV: 'https://comic.vket.com',
  GAMEV: 'https://www.game.v-market.work/v0/',
  ACCOUNT: 'https://account.vket.com',
  AVATAR_MAKER: 'https://avatarmaker.vket.com/edit/',
  AVATAR_MAKER_LP: 'https://avatarmaker.vket.com/',
  BOOTH_MAKER: {
    JP: 'https://vket.com/vketboothmaker-marche',
    EN: 'https://vket.com/en/vketboothmaker-marche',
  },
  THREAD_TALE: 'https://threadtale.vket.com/',
  LICENSE_TOP: 'https://cloud.vket.com/',
  VKET_CLOUD_ASSET_STORE_DEV: 'https://cloud.hikky.dev/account/asset/store',
  VKET_CLOUD_ASSET_STORE_DEV_EN:
    'https://cloud.hikky.dev/en/account/asset/store',
  VKET_CLOUD_ASSET_STORE: 'https://cloud.vket.com/account/asset/store',
  VKET_CLOUD_ASSET_STORE_EN: 'https://cloud.vket.com/en/account/asset/store',
  ROOM: '/myroom',
  TALKROOM: '/talkroom',
  EVENT: '/play/event',
  WORLD: '/play/world',
  CLOUDSTORE: '/cloudstore',
  HELP: '/help',
  MYPAGE_EVENT_NEW: '/mypage/event/new',
  // 規約・SNS・その他
  TWITTER: 'https://twitter.com/VketCloud',
  TERMS: {
    JP: 'https://account.vket.com/terms?locale=ja#my-vket',
    EN: 'https://account.vket.com/terms?locale=en#my-vket',
  },
  POLICY: 'https://www.hikky.co.jp/legal/privacy',
  COMMERCE: 'https://account.vket.com/terms#specified-commercial-transactions',
  HOW_TO_USE: '/guide',
  COMPANY: 'https://www.hikky.co.jp',
  CONTACT: '/contact',
  LICENSE: 'https://cloud.vket.com/license',
  LAB: 'https://lab.vketcloud.com/',
  SDK_BETA: 'https://lab.vketcloud.com/make/',
  PRIVACY: {
    JP: 'https://account.vket.com/terms?locale=ja#privacy',
    EN: 'https://account.vket.com/terms?locale=en#privacy',
  },
  TOLASS_CHAN: 'https://magazine.vket.com/n/n54d30d1bee08',
  ZENGIN: 'https://zengin.ajtw.net/',
  CLOUDSTORE_AVATAR_PARTS: {
    JP: 'https://vket.com/cloudstore/list/avatar_part?sort=newer',
    EN: 'https://vket.com/en/cloudstore/list/avatar_part?sort=newer',
  },
  STAMPRALLY2024: 'https://medal2024s.vket.com',
  ABOUT_VKETBOX: 'https://magazine.vket.com/n/n847bb24b1f7e',
} as const

export const TARGET_AGE = {
  allAges: 'all_ages',
  r18: 'r18',
} as const

export const MAX_AVATAR = 20

export const OFFICIAL_VKET_ID = 'myvket_official'

export const URLREGEX = /(https?:\/\/[^\s]+)/g

export const ROOM_CAPACITY = 15

export const TAG_TYPE = {
  PRIMARY: 'primary',
  LIMITED: 'limited',
  PINK: 'pink',
  BLUE: 'blue',
  OUTLINE: 'outline',
  GRAY: 'gray',
  DANGER: 'danger',
} as const

export const PRESET_HOUSING_NAME = {
  PINK: 'スウィートピンク',
  NATURAL: 'ナチュラルライト',
  CHIC: 'シックモダン',
  GREEN: 'エバーグリーン',
  PURPLE: 'ミスティックパープル',
  MUSV: 'チルアウト',
  KANAHEI: 'ゆるっとルーム',
  SORGE: 'Sorgeのキャンプ場',
  SIMPLE: 'シンプル',
  MIYAVI: 'MIYAVI Special Room',
  HALLOWEEN: 'トリックオアトリート',
  GAJOEN: 'ホテル牙城園',
  WAFU: '五色屋＜払暁＞',
  CLASSROOM: 'みんなの教室＜V組＞',
  WHITEROOM: 'ホワイトルーム',
} as const

export const PWA_INSTALL_LAST_CONFIRM_KEY = 'PWA_INSTALL_LAST_CONFIRM'
export const PWA_INSTALL_DISMISS_COUNT_KEY = 'PWA_INSTALL_DISMISS_COUNT'
export const PWA_LAUNCHED_KEY = 'PWA_LAUNCHED'
export const WEB_PUSH_TOKEN_STORAGE_KEY = 'WEB_PUSH_TOKEN_REGISTER'
export const WEB_PUSH_LAST_CONFIRM_KEY = 'WEB_PUSH_LAST_CONFIRM'
export const WEB_PUSH_DENIED_COUNT_KEY = 'WEB_PUSH_DENIED_COUNT'

/** トラスとウェッジに使用されているマテリアルIDのリスト */
export const TOLASS_AND_WEDGE_MATERIAL = [
  'H_F_0038_000',
  'T_TLS_0002_001',
  'B_TLS_0002_001',
  'S_TLS_0002_001',
  'I_TLS_0001_001',
  'I_TLS_0002_001',
  'F_F_0003_001',
  'M_TLS_0001_001',
  'O_TLS_0001_000',
  'I_TLS_0003_000',
]
/** チセに使用されているマテリアルIDのリスト */
export const CHISE_MATERIAL = [
  'F_F_0004_000',
  'H_F_0039_000',
  'M_CSE_0001_001',
  'T_CSE_0001_001',
  'B_CSE_0001_001',
  'S_CSE_0001_001',
  'I_CSE_0000_000',
  'I_CSE_0002_001',
  'I_CSE_0003_001',
]
/** デルタフレアに使用されているマテリアルIDのリスト */
export const DELTAFLAIR_MATERIAL = [
  'F_F_0006_000',
  'H_F_0041_000',
  'M_DEF_0000_000',
  'T_DEF_0000_000',
  'B_DEF_0000_000',
  'B_DEF_0000_001',
  'S_DEF_0000_000',
  'I_DEF_0000_000',
  'I_DEF_0000_001',
  'I_DEF_0001_000',
  'I_DEF_0001_001',
  'I_DEF_0002_000',
  'I_DEF_0003_000',
  'I_DEF_0003_001',
]
/** 華夜に使用されているマテリアルIDのリスト */
export const KAYA_MATERIAL = [
  'F_F_0007_000',
  'H_F_0042_000',
  'M_KAY_0000_000',
  'T_KAY_0000_000',
  'B_KAY_0000_000',
  'S_KAY_0000_000',
  'I_KAY_0000_000',
  'I_KAY_0001_000',
  'I_KAY_0002_000',
]
/** シアンに使用されているマテリアルIDのリスト */
export const CYAN_MATERIAL = [
  'F_F_0005_000',
  'H_F_0040_000',
  'M_CIA_0000_000',
  'T_CIA_0000_000',
  'T_CIA_0000_001',
  'B_CIA_0000_000',
  'S_CIA_0000_000',
  'I_CIA_0000_000',
  'I_CIA_0000_001',
  'I_CIA_0001_000',
  'I_CIA_0002_000',
]

/** ローカルストレージのキー */
export const LOCAL_STORAGE_TUTORIAL_REDIRECT_URL_KEY = 'tutorialRedirectUrl'
